











































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton, BCol, BRow, BCard, BInputGroup, BCardTitle, BSpinner, BInputGroupAppend, BFormTextarea, BTable, BTab, BTabs,
  BFormCheckboxGroup,
  BFormCheckbox,
    BFormSelectOption
} from "bootstrap-vue";
import {VueGoodTable} from 'vue-good-table'
import {NotifyInfo, NotifySuccess} from "@/helpers/Toast";
import FlowCollection = RegistrationsAPI.Flows.FlowCollection;

@Component({
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BSpinner,
    BInputGroupAppend,
    BTable,
    BTabs,
    BTab,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelectOption
  }
})
export default class GdprRequests extends Vue {
  Processing = false;  
  Id: string = null;
  FlowContainer: FlowCollection = null;
  
  FlowContainers: Array<FlowCollection> = [];
  
  async mounted() {
    await this.getItems();
  }

  async getItems() {
    this.FlowContainers = await this.$api.registrations.flows.GetFlowContainers();
  }
  
  async ReindexById() {
    this.Processing = true;
    
    const reindex = await this.$api.registrations.flows.ReindexById(this.Id);
    if (reindex) {
      NotifySuccess([`Successfully reindexed by id ${this.Id}`]);
    } else {
      NotifyInfo([`Failed to reindex by id ${this.Id}`]);
    }
    this.Processing = false;
  }

  async ReindexFlowContainer() {
    this.Processing = true;
    
    const reindex = await this.$api.registrations.flows.GetFlowContainer(this.FlowContainer.id, false, false, true);
    if (reindex.id == this.FlowContainer.id) {
      NotifySuccess([`Successfully reindexed flow container: ${this.FlowContainer.name}`]);
    } else {
      NotifyInfo([`Failed to reindex flow ${this.FlowContainer.name}`]);
    }

    this.Processing = false;
  }
}
